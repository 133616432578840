import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Img from "gatsby-image";
const Feature = ({ data }) => {
  const {
    title,
    discription,
    long_discription,
    image,
    side_image,
    side_heading,
    icons,
    path,
    preview_url,
    indepthfeatures,
  } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout>
      <SEO
        seo={{
          title: title,
          description: discription,
          previewURL: preview_url,
        }}
      />
      <div className="max-w-6xl mx-auto mt-20 py-12 px-5 md:px-8" id="feature">
        <div className="w-full flex flex-wrap my-8">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="flex mt-12 mb-4 w-1/3">
              {icons.map((iconobject) => {
                return (
                  <div className="mr-2 w-1/5">
                    <img
                      className={`${
                        iconobject.include ? "opacity-100" : "opacity-25"
                      }`}
                      src={iconobject.icon.publicURL}
                      alt={`${title}-icon`}
                    ></img>
                  </div>
                );
              })}
            </div>
            <h3 className="text-5xl font-bold mb-4 ">{title}</h3>
            <p className="text-lg text-textcolor text-left">{discription}</p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end items-center">
            <img
              className="w-1/2"
              src={image.publicURL}
              alt={`${title}-preview`}
            ></img>
          </div>
        </div>
        <div className=" flex justify-between flex-wrap py-4 md:py-24">
          {indepthfeatures.map((edge) => {
            const { title, discription, icon, theme } = edge;

            return (
              <div className="w-full md:w-5/12 px-8 py-12 my-4 feature-card ">
                <div>
                  <div className="flex my-6 items-center">
                    <div className="w-1/4">
                      <div
                        className={`bg-${theme} rounded-full flex items-center justify-center icon-holder`}
                      >
                        <img
                          alt={`${title}-icon`}
                          src={icon.publicURL}
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="w-3/4">
                      <h2 className="inline-block my-1 mx-5  text-xl md:text-2xl font-bold">
                        {title}
                      </h2>
                    </div>
                  </div>
                  <p className="text-md text-textcolor text-left">
                    {discription}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex md:flex-no-wrap flex-wrap-reverse  my-16">
          <div className="w-full md:w-1/2">
            <div className="w-full md:w-10/12 mt-8 md:mt-0 ">
              <h3 className="text-2xl md:text-4xl font-bold text-center md:text-left">
                {side_heading}
              </h3>
              <div
                className="mt-12 text-lg leading-tight text-textcolor"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </div>
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <div className="w-4/5">
              <img src={side_image.publicURL} alt="wizard-with-laptop" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        discription
        long_description
        preview_url
        image {
          publicURL
        }
        side_image {
          publicURL
        }
        side_heading
        icons {
          icon {
            publicURL
          }
          include
        }
        indepthfeatures {
          title
          discription
          theme
          icon {
            publicURL
          }
        }
      }
      html
    }
  }
`;

export default Feature;
